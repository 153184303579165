.button-container {
	display: flex;
	margin: auto;
	width: 220px;
	margin-bottom: 20px;
}

.arrow-button {
	color: var(--dna-puhdasvalkoinen);
	width: 0px;
	height: 0px;
	border: 50px solid var(--dna-musta);
	border-top-color: transparent;
	border-right-color: transparent;
	border-bottom-color: transparent;
	border-left-color: transparent;
}

.arrow-button.right {
	border-left-color: var(--dna-musta);
	margin-left: 10px;
}

.arrow-button.left {
	border-right-color: var(--dna-musta);
	margin-right: 10px;
}

.kanban-container {
	height: 100%;
}

.kanban-page-container {
	height: 100%;
	min-height: 700px;
}

.kanban-card-container {
	height: 100%;
}

.kanban-card {
	width: 100%;
	height: 100%;
	background-color: var(--dna-puhdasvalkoinen);
	min-width: 300px;
	box-shadow: 2px 2px 10px var(--dna-backgroundgrey);
	position: relative;
}

.kanban-card .items-center {
	width: fit-content;
	min-width: 300px;
}

.kanban-card-header {
	width: 100%;
	height: 15%;
	background-color: var(--dna-kuumapinkki);
	display: flex;
	align-items: center;
	justify-content: center;

}

.kanban-card-header header {
	color: var(--dna-puhdasvalkoinen);
	font-size: 2.875rem;

}

.progress-wrapper {
	display: initial;
	align-items: initial;
	position: absolute;
	top: 20%;
	left: 2.5%;
	width: 95%;
}

.progress-wrapper span {
	color: black !important;
	white-space: nowrap;
	-webkit-transform: translateX(50%);
	/* WebKit */
	-moz-transform: translateX(50%);
	/* Mozilla */
	-o-transform: translateX(50%);
	/* Opera */
	-ms-transform: translateX(50%);
	/* Internet Explorer */
	transform: translateX(50%);
	/* CSS3 */
}

.progress-wrapper-upper span {
	margin-top: -100px;
}

.progress-wrapper-lower span {
	margin-top: 100px;
}

.progress-wrapper-right span {
	-webkit-transform: translateX(0%);
	/* WebKit */
	-moz-transform: translateX(0%);
	/* Mozilla */
	-o-transform: translateX(0%);
	/* Opera */
	-ms-transform: translateX(0%);
	/* Internet Explorer */
	transform: translateX(0%);
	/* CSS3 */
}

.progress-wrapper-left span {
	-webkit-transform: translateX(100%);
	/* WebKit */
	-moz-transform: translateX(100%);
	/* Mozilla */
	-o-transform: translateX(100%);
	/* Opera */
	-ms-transform: translateX(100%);
	/* Internet Explorer */
	transform: translateX(100%);
	/* CSS3 */
}

.recharts-responsive-container {
	position: absolute;
	top: 35%;
	left: 10%;
}

.custom-tooltip {
	background: var(--dna-puhdasvalkoinen);
	border: solid 1px var(--dna-musta);
	border-radius: 10px;
	padding: 20px;
	width: fit-content;
}